<template>
  <SdPage
    id="billing-page"
  >
    <SdPageHeader
      title="Billing"
      class="mb-5"
    />
    <SdLoadingLayer :init="init">
      <template #loaded>
        <AddBusinessCreditsDialog
          ref="addBusinessCreditsDialog"
        />
        <template v-if="subscription">
          <div
            id="subscription-plan"
            class="mb-5"
          >
            <div class="row no-gutters align-items-center">
              <div
                v-if="subscription.has_scheduled_cancellation"
                class="col-12 mb-3"
              >
                <ElAlert
                  type="info"
                  show-icon
                  :closable="false"
                >
                  <ElSpinner
                    v-if="undoSubscriptionScheduledChangeLoading"
                    color="primary"
                    class="text-center"
                  />
                  <template v-else>
                    As requested, your subscription will be cancelled {{ resolvedSubscriptionTypeText }} ({{ resolvedSubscriptionCancelAtDate }}).
                    <ElButton
                      type="text"
                      size="medium"
                      @click="undoSubscriptionScheduledChange"
                    >
                      Undo cancellation
                    </ElButton>
                  </template>
                </ElAlert>
              </div>
              <div
                v-else-if="subscription.scheduled_downgrade_plan"
                class="col-12 mb-3"
              >
                <ElAlert
                  type="info"
                  show-icon
                  :closable="false"
                >
                  <ElSpinner
                    v-if="undoSubscriptionScheduledChangeLoading"
                    color="primary"
                    class="text-center"
                  />
                  <template v-else>
                    As requested, your subscription will change from the
                    {{ subscription.plan.name.capitalize() }} to the
                    {{ subscription.scheduled_downgrade_plan.name.capitalize() }} plan
                    at the end of this billing cycle ({{ nextSubscriptionChargeDate }}).
                    <ElButton
                      type="text"
                      size="medium"
                      @click="undoSubscriptionScheduledChange"
                    >
                      Undo switch
                    </ElButton>
                  </template>
                </ElAlert>
              </div>
              <span class="col-12 mt-2 font-17 font-weight-strong">
                Next Invoice
              </span>
              <span class="col-12 mt-2 font-15">
                {{ billingCycleDates }}
              </span>
              <div
                v-if="!planIsStandardType && subscription.plan.price > 0 && (!subscription.has_scheduled_cancellation || subscription.is_on_contract)"
                class="col-auto font-13 mt-3"
              >
                Next charge: <b>${{ nextSubscriptionChargeAmount }}</b> on <b>{{ nextSubscriptionChargeDate }}</b>
              </div>
              <div
                class="col-12 mt-3"
              >
                <ElButton
                  v-if="!planIsStandardType"
                  size="medium"
                  class="mr-3"
                  @click="modifySubscription"
                >
                  Modify plan
                </ElButton>
                <BillingStripeItemsTable
                  :subscription="subscription"
                />
                <ElButton
                  v-if="!subscription.has_scheduled_cancellation"
                  size="medium"
                  @click="cancelSubscription"
                >
                  Cancel Subscription
                </ElButton>
              </div>
            </div>
            <BusinessAccountBalance
              @openAddCreditDialog="openAddCreditDialog"
            />
          </div>

          <h2>
            Payment method
          </h2>
          <SdPaymentMethodForm :editable="isAccountOwner" />

          <template v-if="!(subscription.has_scheduled_cancellation || subscription.scheduled_downgrade_plan)">
            <template v-if="!planIsStandardType">
              <ModifyPlanDialog
                ref="modifyPlanDialog"
                :subscription="subscription"
                @modified="subscriptionChanged"
              />
            </template>
            <template v-if="planIsStandardType || subscription.plan.price > 0">
              <CancelPlanDialog
                ref="cancelPlanDialog"
                :subscription="subscription"
                @cancelled="subscriptionChanged"
              />
            </template>
            <SubscriptionChangeSuccessDialog ref="subscriptionChangeSuccessDialog" />
          </template>
        </template>
        <template v-else>
          <ElAlert
            id="renew-subscription"
            class="align-items-center"
            type="info"
            show-icon
            :closable="false"
          >
            <span v-if="isAccountOwner">
              Your Showdigs subscription ended on {{ subscriptionEndedAt }}. You are currently not subscribed to Showdigs.
              Please contact <a
                :href="`mailto:hello@showdigs.com?subject=Subscription Cancellation From: ${user.email}`"
                target="_blank"
              >hello@showdigs.com</a> if you wish to renew your subscription.
            </span>
            <span v-else>
              Only account owners can view this page, please contact your account owner.
            </span>
          </ElAlert>
        </template>
      </template>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import SdPaymentMethodForm from '@/components/common/form/SdPaymentMethodForm';
import { computed, ref } from '@vue/composition-api';
import { isStandardTypePlan } from '@/utils/PlanUtil';
import momentUtil from '@/utils/MomentUtil';
import { showErrorNotify } from '@/utils/NotifyUtil';
import Role from '@/constants/Role';
import Plan from '@/constants/Plan';
import ModifyPlanDialog from '@/components/billing/ModifyPlanDialog';
import SubscriptionChangeSuccessDialog from '@/components/billing/SubscriptionChangeSuccessDialog';
import BusinessAccountBalance from '@/components/billing/BusinessAccountBalance';
import CancelPlanDialog from '@/components/billing/CancelPlanDialog';
import AddBusinessCreditsDialog from '@/components/billing/AddBusinessCreditsDialog';
import BillingStripeItemsTable from '@/components/billing/BillingStripeItemsTable';
import { loadTransactions } from '@/utils/TransactionUtil';
import { Transaction } from '@/constants/Transaction';
import get from 'lodash.get';

export default {
  name: 'Billing',
  components: {
    CancelPlanDialog,
    SubscriptionChangeSuccessDialog,
    ModifyPlanDialog,
    SdPaymentMethodForm,
    BusinessAccountBalance,
    AddBusinessCreditsDialog,
    BillingStripeItemsTable,
  },
  setup(props, context) {
    const { $store } = context.root;
    const subscription = ref();
    const nextSubscriptionChargeDate = ref();
    const nextSubscriptionChargeAmount = ref();
    const user = $store.state.Auth.user;
    const isAccountOwner = user.role === Role.ACCOUNT_OWNER;
    const vacancyCounts = ref({});
    const undoSubscriptionScheduledChangeLoading = ref(false);
    const subscriptionEndedAt = ref();
    const planIsStandardType = computed(computePlanIsStandardType);
    const billingCycleDates = computed(computeBillingCycleDates);

    if ($store.getters['Auth/accountIsInactive']) {
      const subscriptionPeriodEndAt = $store.getters['Auth/lastSubscription'].current_period_end_at ?? null;
      subscriptionEndedAt.value = momentUtil(subscriptionPeriodEndAt, momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear();
    }

    const resolvedSubscriptionTypeText = ref();
    const resolvedSubscriptionCancelAtDate = ref();

    return {
      init,
      Plan,
      user,
      subscription,
      nextSubscriptionChargeDate,
      nextSubscriptionChargeAmount,
      undoSubscriptionScheduledChangeLoading,
      isAccountOwner,
      planIsStandardType,
      modifySubscription,
      cancelSubscription,
      undoSubscriptionScheduledChange,
      subscriptionChanged,
      vacancyCounts,
      subscriptionEndedAt,
      openAddCreditDialog,
      resolvedSubscriptionTypeText,
      resolvedSubscriptionCancelAtDate,
      billingCycleDates,
    };

    async function init() {
      if (!isAccountOwner) {
        return;
      }

      subscription.value = await $store.dispatch('Subscription/get');

      if (!subscription.value) {
        return;
      }

      const included = subscription.value.plan.included_unit_activations;
      const used = Math.min(subscription.value.current_month_activated_units_count, included);

      const transactions = await loadTransactions($store);
      const listingShieldActivation = transactions.filter((transaction) => [Transaction.TYPE_ACTIVATION, Transaction.TYPE_ACTIVATION_WITH_PHONE_LINE].includes(transaction.type) && get(transaction, 'unit_showing_profile.lease_period.has_listing_shield')).length;
      const additionalActivations = !subscription.is_new_pricing_model
          ? subscription.value.current_month_activated_units_count
          : Math.max(0, subscription.value.current_month_activated_units_count - used);

      nextSubscriptionChargeDate.value = momentUtil(subscription.value.current_period_end_at, momentUtil.tz.guess())
        .toDisplayFormatMonthDayAndYearSt();
      nextSubscriptionChargeAmount.value = subscription.value.scheduled_downgrade_plan
        ? subscription.value.scheduled_downgrade_plan.price
        : subscription.value.plan.price;

      resolvedSubscriptionCancelAtDate.value = nextSubscriptionChargeDate.value;

      if (subscription.value.has_scheduled_cancellation && subscription.value.contract_ends_at) {
        const cancellationDate = momentUtil(subscription.value.contract_ends_at, momentUtil.tz.guess());
        const now = momentUtil(null, momentUtil.tz.guess());
        if (now.isBefore(cancellationDate)) {
          resolvedSubscriptionCancelAtDate.value = cancellationDate.toDisplayFormatMonthDayAndYearSt();
        }
      }
      const sumActivations = additionalActivations * subscription.value.plan.additional_unit_activation_price;
      const sumListingShields = listingShieldActivation * 30;

      nextSubscriptionChargeAmount.value = Math.max(nextSubscriptionChargeAmount.value + sumListingShields, sumActivations + sumListingShields);

      vacancyCounts.value = {
        additionalActivations,
        included,
        used,
      };

      resolvedSubscriptionTypeText.value = resolveSubscriptionTypeText();
    }

    async function undoSubscriptionScheduledChange() {
      if (undoSubscriptionScheduledChangeLoading.value) {
        return;
      }
      try {
        undoSubscriptionScheduledChangeLoading.value = true;
        const action = subscription.value.has_scheduled_cancellation ? 'cancel' : 'modify';
        await $store.dispatch(`Subscription/${action}`);
        window.location.reload();
      } catch (error) {
        undoSubscriptionScheduledChangeLoading.value = false;
        showErrorNotify(context, error.message);
      }
    }

    async function modifySubscription() {
      context.refs.modifyPlanDialog.open();
    }

    async function cancelSubscription() {
      context.refs.cancelPlanDialog.open();
    }

    function computeBillingCycleDates() {
      const periodEnd = momentUtil(subscription.value.current_period_end_at, momentUtil.tz.guess()).toDisplayFormatMonthDayAndYear();
      return `${periodEnd}`;
    }

    function openAddCreditDialog() {
      context.refs.addBusinessCreditsDialog.open();
    }

    function subscriptionChanged(data) {
      context.refs.subscriptionChangeSuccessDialog.open(data);
    }

    function computePlanIsStandardType() {
      if (!subscription.value) {
        return false;
      }
      return isStandardTypePlan(subscription.value.plan);
    }

    function resolveSubscriptionTypeText() {
      if (subscription.value.is_on_contract) {
        return 'when your contract ends on';
      }
      return 'at the end of this billing cycle';
    }
  },
};
</script>

<style lang="scss">
#billing-page {
  max-width: 66.66667%;
  @include media-breakpoint-down(md) {
    max-width: 100%;
  }
  h2 {
    font-size: $--font-size-large;
  }

  #subscription-plan {
    border: 1px solid gray-color(light);
    padding: 2rem;

    button.el-button--text {
      font-size: 15px;
      padding: 0;
    }
  }

  .el-table {
    thead {
      color: gray-color(darker);
    }
    th {
      background: gray-color('lighter');
    }
    .el-table__row:not(:nth-last-child(2)) td {
      border-bottom: none;
    }
    .el-table__row:last-child {
      font-weight: bold;
    }
  }

  #renew-subscription {
    .el-alert__content {
      width: 100%;
    }
  }
}
</style>
